import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/reacteev/reacteev-website/src/components/mdx-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MatomoTrackingConfiguration = makeShortcode("MatomoTrackingConfiguration");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Conformément aux dispositions des articles 6-III et 19 de la Loi n° 2004-575 du 21 juin 2004 pour la Confiance dans l’économie numérique, dite L.C.E.N., nous portons à la connaissance des utilisateurs et visiteurs du site `}<a parentName="p" {...{
        "href": "https://reacteev.com"
      }}>{`https://reacteev.com`}</a>{`, les informations suivantes :`}</p>
    <section><h2 parentName="section" {...{
        "id": "description-des-services-fournis"
      }}>{`Description des services fournis`}</h2><p parentName="section">{`Le site ”`}<a parentName="p" {...{
          "href": "https://reacteev.com"
        }}>{`https://reacteev.com`}</a>{`” a pour objet de fournir une information concernant l’ensemble des activités de la société. Le propriétaire du site s’efforce de fournir sur ce site des informations aussi précises que possible. Toutefois, il ne pourra être tenu responsable des omissions, des inexactitudes et des carences dans la mise à jour, qu’elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces informations. Toutes les informations proposées sur le site `}<a parentName="p" {...{
          "href": "https://reacteev.com"
        }}>{`https://reacteev.com`}</a>{` sont données à titre indicatif, sont non exhaustives, et sont susceptibles d’évoluer. Elles sont données sous réserve de modifications ayant été apportées depuis leur mise en ligne.`}</p></section>
    <section><h2 parentName="section" {...{
        "id": "propriété-intellectuelle-et-contrefaçons"
      }}>{`Propriété intellectuelle et contrefaçons`}</h2><p parentName="section">{`Le proprietaire du site est propriétaire des droits de propriété intellectuelle ou détient les droits d’usage sur tous les éléments accessibles sur le site, notamment les textes, images, graphismes, logo, icônes, sons, logiciels… Toute reproduction, représentation, modification, publication, adaptation totale ou partielle des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable par email à `}<a parentName="p" {...{
          "href": "mailto:contact@reacteev.com"
        }}>{`contact@reacteev.com`}</a>{`. Toute exploitation non autorisée du site ou de quelconque des éléments qu’il contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.`}</p></section>
    <section><h2 parentName="section" {...{
        "id": "gestion-des-données-personnelles"
      }}>{`Gestion des données personnelles`}</h2><p parentName="section">{`Sur ce site, le propriétaire du site ne collecte des informations personnelles relatives à l’utilisateur que pour le besoin de certains services proposés par le site : Nous contacter & Prendre un rendez-vous. L’utilisateur fournit ces informations en toute connaissance de cause, notamment lorsqu’il procède par lui-même à leur saisie. Reacteev s’engage donc à l’obligation de ne pas fournir ces informations. Conformément aux dispositions des articles 38 et suivants de la loi 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, tout utilisateur dispose d’un droit d’accès, de rectification, de suppression et d’opposition aux données personnelles le concernant. Pour l’exercer, adressez votre demande à `}<a parentName="p" {...{
          "href": "mailto:dpo@reacteev.com"
        }}>{`dpo@reacteev.com`}</a>{`.`}</p><section parentName="section"><h3 parentName="section" {...{
          "id": "refus-de-suivi"
        }}>{`Refus de suivi`}</h3><MatomoTrackingConfiguration mdxType="MatomoTrackingConfiguration" /></section></section>
    <section><h2 parentName="section" {...{
        "id": "éditeur"
      }}>{`Éditeur`}</h2><p parentName="section">{`Reacteev`}<br parentName="p"></br>{`
`}{`SAS au capital de 3 300 Euros`}<br parentName="p"></br>{`
`}{`SIREN : 811144062 RCS Paris`}<br parentName="p"></br>{`
`}{`Siège social : 5 Rue du Mail, 75002 Paris, France`}<br parentName="p"></br>{`
`}{`N° de TVA intracommunautaire : FR 85 811144062`}</p></section>
    <section><h2 parentName="section" {...{
        "id": "directeur-de-la-publication"
      }}>{`Directeur de la publication`}</h2><p parentName="section">{`Monsieur Nicolas Morin, Directeur Général de Reacteev.`}</p></section>
    <section><h2 parentName="section" {...{
        "id": "hébergeur"
      }}>{`Hébergeur`}</h2><p parentName="section">{`SCALEWAY SAS`}<br parentName="p"></br>{`
`}{`BP 438`}<br parentName="p"></br>{`
`}{`75366 Paris Cedex 08`}<br parentName="p"></br>{`
`}{`France`}</p></section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      